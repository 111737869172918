import React from 'react';
// import sections
import Clients from '../../components/sections/Clients';
// import some required elements
import ProductMain from "../../components/sections/ProductMain";
import image from "../../assets/bitsonic/certainty.jpg";


class Certainty extends React.Component {

  render() {

    const genericData = {
        headingText: 'Certainty',
        mainText: [
          'Still trying to place your music while plagued by numerous data sources, missing royalties and incomplete metadata reporting?',
          'At BitSonic we rapidly provide accurate, clean and accessible data that integrates multiple sources into a single, normalized dataset',
          'You will have a searchable and a versatile catalogue management tool, supporting effective sync placement and licensing of your tracks'
        ]
      };

    return (
      <React.Fragment>
        <ProductMain className="illustration-section-01"
                     image={image}
                     mainText={genericData.mainText}
                     headingText={genericData.headingText}/>
        <Clients topDivider bottomDivider />
      </React.Fragment>
    );
  }
}

export default Certainty;
