import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutProduct from "./layouts/LayoutProduct";

// Products
import Home from './views/Home';
import DueDiligence from "./views/products/DueDiligence";
import CatalogueIngestion from "./views/products/CatalogueIngestion";
import RoyaltyAudit from "./views/products/RoyaltyAudit";
import IncomeOptimization from "./views/products/IncomeOptimization";
import Metadata from "./views/products/Metadata";

// Values
import Bespoke from "./views/values/Bespoke";
import Authoritative from "./views/values/Authoritative";
import Certainty from "./views/values/Certainty";

// Images for preloading
import WeDoImg from "./assets/bitsonic/main-we-do.jpg";
import HowWeDoImg from "./assets/bitsonic/main-how-we-do-it.jpg";
import CertaintyImg from "./assets/bitsonic/certainty.jpg";
import CatalogueIngestionImg from "./assets/bitsonic/catalogue_ingestion.jpg";
import DueDiligenceImg from "./assets/bitsonic/due_diligence.jpg";
import AuditImg from "./assets/bitsonic/audit.jpg";
import BespokeImg from "./assets/bitsonic/bespoke.jpg";
import AuthoritativeImg from "./assets/bitsonic/authoritative.jpg";
import MetadataImage from "./assets/bitsonic/metadata.jpg";

let images = [];

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();

    const imagesList = [WeDoImg, HowWeDoImg, CertaintyImg, CatalogueIngestionImg, DueDiligenceImg, AuditImg, BespokeImg, AuthoritativeImg, MetadataImage];
    for (let i = 0; i < imagesList.length; i++) {
      images[i] = new Image();
      images[i].src = imagesList[i];
    }
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            {/**/}
            <AppRoute exact path="/authoritative" component={Authoritative} layout={LayoutProduct} />
            <AppRoute exact path="/bespoke" component={Bespoke} layout={LayoutProduct} />
            <AppRoute exact path="/certainty" component={Certainty} layout={LayoutProduct} />
            {/**/}
            <AppRoute exact path="/due-diligence" component={DueDiligence} layout={LayoutProduct} />
            <AppRoute exact path="/catalogue-ingestion" component={CatalogueIngestion} layout={LayoutProduct} />
            <AppRoute exact path="/royalty-audit" component={RoyaltyAudit} layout={LayoutProduct} />
            <AppRoute exact path="/income-optimization" component={IncomeOptimization} layout={LayoutProduct} />
            <AppRoute exact path="/metadata" component={Metadata} layout={LayoutProduct} />
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);
