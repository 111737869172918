import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import DropdownMenu from "../elements/DropdownMenu";
import {NavDropdown} from "react-bootstrap";

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  hideLogo: false,
  bottomOuterDivider: false,
  bottomDivider: false,
  hasDropdown: false,
  showHome: false
}

class Header extends React.Component {

  state = {
    isActive: false
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + 'px';
    this.setState({ isActive: true });
  }

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
  }

  keyPress = (e) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  }

  clickOutside = (e) => {
    if (!this.nav.current) return
    if (!this.state.isActive || this.nav.current.contains(e.target) || e.target === this.hamburger.current) return;
    this.closeMenu();
  }

  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      hasDropdown,
      showHome,
      hideLogo,
      ...props
    } = this.props;

    const classes = classNames(
      'site-header',
      bottomOuterDivider && 'has-bottom-divider',
      className
    );

    const valuesItems = [
      {name: 'Authoritative', link: '/authoritative'},
      {name: 'Bespoke', link: '/bespoke'},
      {name: 'Certainty', link: '/certainty'}
    ];

    const productsItems = [
      {name: 'Due Diligence', link: '/due-diligence'},
      {name: 'Catalogue Ingestion', link: '/catalogue-ingestion'},
      {name: 'Royalty Audit', link: '/royalty-audit'},
      {name: 'Income Optimization', link: '/income-optimization'},
      {name: 'Metadata', link: '/metadata'}
    ];

    return (
      <header
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-header-inner',
              bottomDivider && 'has-bottom-divider'
            )}>
            {!hideLogo ? <Logo /> : null}
            {!hideNav &&
            <React.Fragment>
              <button
                ref={this.hamburger}
                className="header-nav-toggle"
                onClick={this.state.isActive ? this.closeMenu : this.openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
              </button>
              <nav
                ref={this.nav}
                className={
                  classNames(
                    'header-nav',
                    this.state.isActive && 'is-active'
                  )}>
                <div className="header-nav-inner header-nav-right">
                  {hasDropdown ? (
                      <ul className={
                        classNames(
                          'list-reset text-xxs',
                          navPosition && `header-nav-${navPosition}`
                        )}>
                        {showHome ? <li className="header-nav-custom">
                          <Link to="/" className="text-xxs color-brand" onClick={this.closeMenu}>Home</Link>
                        </li> : null}
                        <li className="header-nav-custom display-xs-none">
                          <DropdownMenu className={['list-reset text-xxs']} title="Values" items={valuesItems}/>
                        </li>
                        <li className="header-nav-custom display-xs-none">
                          <DropdownMenu className={['list-reset text-xxs']} title="Products" items={productsItems}/>
                        </li>
                        {valuesItems.map((item) => (
                          <li className="header-nav-custom display-xs">
                            <Link to={item.link} className="text-xxs color-brand" onClick={this.closeMenu}>Values – {item.name}</Link>
                          </li>
                        ))}
                        {productsItems.map((item) => (
                          <li className="header-nav-custom display-xs">
                            <Link to={item.link} className="text-xxs color-brand" onClick={this.closeMenu}>Products – {item.name}</Link>
                          </li>
                        ))}
                      </ul>
                  ): (<ul className={
                    classNames(
                      'list-reset text-xxs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <a onClick={this.closeMenu}>Products</a>
                    </li>
                  </ul>)}
                  {!hideSignin &&
                  <ul
                    className="list-reset header-nav-right"
                  >
                    <li>
                      <Link to="/signup/" className="button button-primary button-wide-mobile button-sm" onClick={this.closeMenu}>Sign up</Link>
                    </li>
                  </ul>}
                </div>
              </nav>
            </React.Fragment>}
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
