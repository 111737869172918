import React from 'react';
// import section header
// import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroSplit from '../components/sections/HeroSplit';
import Clients from '../components/sections/Clients';
import GenericSection from '../components/sections/GenericSection';
// import FeaturesTiles from '../components/sections/FeaturesTiles';
// import Testimonial from '../components/sections/Testimonial';
// import Cta from '../components/sections/Cta';
// import some required elements
// import Modal from '../components/elements/Modal';
// import VideoImage from "./../assets/images/video-placeholder.svg";
import WeDoImg from "./../assets/bitsonic/main-we-do.jpg";
import HowWeDoImg from "./../assets/bitsonic/main-how-we-do-it.jpg";
import ProductMain from "../components/sections/ProductMain";



class Home extends React.Component {
  state = {
    videoModalActive: false
  }
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {
    const genericData = {
      headingText: 'What we do',
      mainText: [
        'Provide due diligence, ingestion, auditing and income optimization of music catalogues and royalties',
        'Deliver accurate, fast and effective inventory and analysis of catalogues',
        'Innovate with AI-based predictive analytics'
      ]
    }

    const genericData_2 = {
      headingText: 'How we do it',
      mainText: [
        'Merge data from multiple fragmented sources into clean, normalized and integrated datasets',
        'Deploy novel software tool for rapid inventory, analysis and advanced queries with unprecedented accuracy',
        'Engage clients on one-to-one basis across the entire industry from music funds to publishers, record producers, managers and artists'
      ]
    }

    return (
      <React.Fragment>
        <HeroSplit className="illustration-section-01" />
        <Clients topDivider bottomDivider />
        <GenericSection topDivider>
          <ProductMain image={WeDoImg}
                       hideContactButton={true}
                       mainText={genericData.mainText}
                       headingText={genericData.headingText}/>
          {/*<div className="reveal-from-bottom">*/}
          {/*  <a*/}
          {/*    data-video="https://player.vimeo.com/video/174002812"*/}
          {/*    href="#0"*/}
          {/*    aria-controls="video-modal"*/}
          {/*    onClick={this.openModal}*/}
          {/*  >*/}
          {/*    <Image*/}
          {/*      src={VideoImage}*/}
          {/*      alt="Video"*/}
          {/*      height={400} />*/}
          {/*  </a>*/}
          {/*</div>*/}
          {/*<Modal*/}
          {/*  id="video-modal"*/}
          {/*  show={this.state.videoModalActive}*/}
          {/*  handleClose={this.closeModal}*/}
          {/*  video="https://player.vimeo.com/video/174002812"*/}
          {/*  videoTag="iframe" />*/}
        </GenericSection>
        <GenericSection topDivider>
          <ProductMain image={HowWeDoImg}
                       hideContactButton={true}
                       mainText={genericData_2.mainText}
                       headingText={genericData_2.headingText}/>
        </GenericSection>
        {/*<FeaturesTiles topDivider className="center-content" />*/}
        {/*<Testimonial topDivider />*/}
      </React.Fragment>
    );
  }
}

export default Home;
