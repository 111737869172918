import React from 'react';
// import sections
import Clients from '../../components/sections/Clients';
// import some required elements
import ProductMain from "../../components/sections/ProductMain";
import image from "../../assets/bitsonic/audit.jpg";


class RoyaltyAudit extends React.Component {

  render() {

    const genericData = {
        headingText: 'Royalty Audit',
        mainText: [
          'Data analysis in support of accounting and business management firms that specialize in royalty audits.'
          // 'Be assured that you have received the correct amount of royalty income due to you and to highlight instances where you are entitled to more',
          // 'Find errors and missing revenues',
          // 'Obtain optimized data to assist contract renegotiations and pricing discussions'
        ]
      };

    return (
      <React.Fragment>
        <ProductMain className="illustration-section-01"
                     image={image}
                     mainText={genericData.mainText}
                     headingText={genericData.headingText}/>
        <Clients topDivider bottomDivider />
      </React.Fragment>
    );
  }
}

export default RoyaltyAudit;
