import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
import Flags from 'country-flag-icons/react/3x2'
// import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer center-content-mobile',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top space-between text-xxs">
              <Logo />
            </div>
            <div className="footer-bottom space-between text-xxs invert-order-desktop">
              <FooterNav />
              <div className="footer-copyright">&copy; 2020-2023 Bloc-A AG</div>
              <div className="d-flex justify-content-center">
                <div>Made with ❤️ in</div>
                <div className="d-flex ms-1">
                  <Flags.CH title="Switzerland" className="me-1 footer-emoji"/>
                  <Flags.EU title="Europe" className="me-1 footer-emoji"/>
                  <Flags.CA title="Canada" className="me-1 footer-emoji"/>
                  <Flags.GB title="United Kingdom" className="me-1 footer-emoji"/>
                  <Flags.IL title="Israel" className="me-1 footer-emoji"/>
                </div>
                <div>and</div>
                <div className="d-flex">
                  <Flags.UA title="Ukraine" className="ms-1 footer-emoji"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
