import React, {Children} from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {Link} from "react-router-dom";

const DropdownMenu = ({
    className,
    title,
    items
  }) => {

  return (
    <Navbar variant="dark" expand="sm" className={className}>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav>
          <NavDropdown
            id="nav-dropdown-dark-example"
            title={title}
            menuVariant="dark"
            className="color-brand"
          >
            {items.map((item) => (
              <NavDropdown.Item className="text-xxs color-brand"><Link to={item.link}>{item.name}</Link></NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default DropdownMenu;
