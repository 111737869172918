import React from 'react';
// import sections
import Clients from '../../components/sections/Clients';
// import some required elements
import ProductMain from "../../components/sections/ProductMain";
import image from "../../assets/bitsonic/due_diligence.jpg";


class DueDiligence extends React.Component {

  render() {

    const genericData = {
        headingText: 'Due Diligence',
        mainText: [
          'Assess catalogue information for buyers, sellers and funders to optimize valuation and investment decisions and to limit financial and legal risk',
          'Examine registration data, chain of title, past contracts, royalty obligations, restrictions, and potential terminations of rights in territories around the world',
          'Analyze sellers payment obligations and earnings over time'
        ]
      };

    return (
      <React.Fragment>
        <ProductMain className="illustration-section-01"
                     image={image}
                     mainText={genericData.mainText}
                     headingText={genericData.headingText}/>
        <Clients topDivider bottomDivider />
      </React.Fragment>
    );
  }
}

export default DueDiligence;
