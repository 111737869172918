import React from 'react';
// import sections
import Clients from '../../components/sections/Clients';
// import some required elements
import ProductMain from "../../components/sections/ProductMain";
import image from "../../assets/bitsonic/metadata.jpg";


class Metadata extends React.Component {

  render() {

    const genericData = {
        headingText: 'Metadata',
        mainText: [
          'Do you find it difficult to deliver your music to the right streaming platform?',
          'We help you ensure the proper context of your songs through better metadata optimization',
          'Add and trace metadata relating to song title and consumption data'
        ]
      };

    return (
      <React.Fragment>
        <ProductMain className="illustration-section-01"
                     image={image}
                     mainText={genericData.mainText}
                     headingText={genericData.headingText}/>
        <Clients topDivider bottomDivider />
      </React.Fragment>
    );
  }
}

export default Metadata;
