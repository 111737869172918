import React from 'react';
// import sections
import Clients from '../../components/sections/Clients';
// import some required elements
import ProductMain from "../../components/sections/ProductMain";
import image from "../../assets/bitsonic/authoritative.jpg";


class Authoritative extends React.Component {

  render() {

    const genericData = {
        headingText: 'Authoritative',
        mainText: [
          'Are you unsure who’s opinion to trust in an industry where stakeholders vie over copyrights, revenue streams and catalogue valuation?',
          'BitSonic offers reliable information, allowing the data to speak for itself to reveal consumption trends, commercial hotspots and likely future revenues',
          'You will be strongly placed to buy, sell or finance rights catalogues'
        ]
      };

    return (
      <React.Fragment>
        <ProductMain className="illustration-section-01"
                     image={image}
                     mainText={genericData.mainText}
                     headingText={genericData.headingText}/>
        <Clients topDivider bottomDivider />
      </React.Fragment>
    );
  }
}

export default Authoritative;
