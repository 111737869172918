import React from 'react';
// import sections
import Clients from '../../components/sections/Clients';
// import some required elements
import ProductMain from "../../components/sections/ProductMain";
import image from "../../assets/bitsonic/income_optimization.jpg";


class IncomeOptimization extends React.Component {

  render() {

    const genericData = {
        headingText: 'Income Optimization',
        mainText: [
          'Do you find the management of multiple paying organizations overly complicated?',
          'We trace your various revenue streams and their methods of collection',
          'Harness new methods to maximize your future catalogue incomes'
        ]
      };

    return (
      <React.Fragment>
        <ProductMain className="illustration-section-01"
                     image={image}
                     mainText={genericData.mainText}
                     headingText={genericData.headingText}/>
        <Clients topDivider bottomDivider />
      </React.Fragment>
    );
  }
}

export default IncomeOptimization;
