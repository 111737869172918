import React from 'react';
// import sections
import Clients from '../../components/sections/Clients';
// import some required elements
import ProductMain from "../../components/sections/ProductMain";
import image from "../../assets/bitsonic/bespoke.jpg";


class Bespoke extends React.Component {

  render() {

    const genericData = {
        headingText: 'Bespoke',
        mainText: [
          'Do you find it hard to get data in the shape most useful and meaningful to you?',
          'Bitsonic’s innovative AI-driven software tool combines the power of machine learning technology tailored to your particular needs',
          'We place people’s ideas, needs and solutions at the heart of music data analytics'
        ]
      };

    return (
      <React.Fragment>
        <ProductMain className="illustration-section-01"
                     image={image}
                     mainText={genericData.mainText}
                     headingText={genericData.headingText}/>
        <Clients topDivider bottomDivider />
      </React.Fragment>
    );
  }
}

export default Bespoke;
