import React from 'react';
// import sections
import Clients from '../../components/sections/Clients';
// import some required elements
import ProductMain from "../../components/sections/ProductMain";
import image from "../../assets/bitsonic/catalogue_ingestion.jpg";


class CatalogueIngestion extends React.Component {

  render() {

    const genericData = {
        headingText: 'Catalogue Ingestion',
        mainText: [
          'Provide accurate, secure, fast and customizable cloud-based catalogue management catering for incremental additions of new songs',
          'Supply a customizable catalogue repository with automated inclusion of new song data and a management dashboard with advanced querying capabilities',
          'Include client specific song metrics over space and time to allow visualization of past and present income'
        ]
      };

    return (
      <React.Fragment>
        <ProductMain className="illustration-section-01"
                     image={image}
                     mainText={genericData.mainText}
                     headingText={genericData.headingText}/>
        <Clients topDivider bottomDivider />
      </React.Fragment>
    );
  }
}

export default CatalogueIngestion;
